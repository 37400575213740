
import { defineComponent, onMounted, ref } from "vue";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
export default defineComponent({
  name: "ImageCropper",
  props: ["modelValue", "height", "src"],
  emits: ["update:modelValue"],
  components: {
    VuePictureCropper,
  },
  setup(_, { emit }) {
    const isShowModal = ref<boolean>(false);
    const pic = ref<string>("");
    const resultUrl = ref<string>("");
    const loading = ref(false);

    function selectFile(e: Event) {
      // Reset last selection and results
      pic.value = "";
      resultUrl.value = "";
      // Get selected files
      const { files } = e.target as HTMLInputElement;
      if (!files || !files.length) return;
      // Convert to dataURL and pass to the cropper component
      const file = files[0];
      emit("update:modelValue", file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Update the picture source of the `img` prop
        pic.value = String(reader.result);
        // Show the modal
        isShowModal.value = true;
      };
    }
    async function getResult() {
      if (!cropper) return;
      loading.value = true;
      const file = await cropper.getFile({
        fileName: new Date().getTime(),
      });
      emit("update:modelValue", file);
      resultUrl.value = cropper.getDataURL();
      loading.value = false;
      clear(false);
    }
    function clear(changeModelValue = true) {
      if (!cropper) return;
      isShowModal.value = false;
      if (changeModelValue) {
        emit("update:modelValue", null);
        cropper.clear();
        resultUrl.value = "";
        pic.value = "";
      }
    }
    function reset() {
      if (!cropper) return;
      cropper.reset();
      emit("update:modelValue", null);
    }
    function secondaryAction(action: string) {
      switch (action) {
        case "move":
        case "crop":
          cropper.setDragMode(action);
          break;

        case "zoom-in":
          cropper.zoom(0.1);
          break;

        case "zoom-out":
          cropper.zoom(-0.1);
          break;

        case "rotate-left":
          cropper.rotate(-90);
          break;

        case "rotate-right":
          cropper.rotate(90);
          break;

        case "flip-horizontal":
          cropper.scaleX(-cropper.getData().scaleX || -1);
          break;

        case "flip-vertical":
          cropper.scaleY(-cropper.getData().scaleY || -1);
          break;

        default:
      }
    }
    return {
      pic,
      resultUrl,
      isShowModal,
      loading,
      // Methods
      selectFile,
      getResult,
      clear,
      reset,
      secondaryAction,
    };
  },
});
