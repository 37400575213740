import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ae9cf50e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-fileupload" }
const _hoisted_2 = { class: "p-d-flex p-p-2 p-justify-between" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_VuePictureCropper = _resolveComponent("VuePictureCropper")!
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_Button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$refs.uploadInput.click())),
        class: "p-button-secondary",
        label: _ctx.$t('choosePhoto'),
        icon: "pi pi-upload"
      }, null, 8, ["label"]),
      _createVNode(_component_BlockUI, {
        blocked: _ctx.loading,
        class: "p-d-flex p-ml-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Button, {
            disabled: !_ctx.isShowModal,
            style: {"width":"30px"},
            class: "p-button-danger",
            icon: "pi pi-times",
            onClick: _cache[1] || (_cache[1] = () => _ctx.clear())
          }, null, 8, ["disabled"]),
          _createVNode(_component_Button, {
            disabled: !_ctx.isShowModal,
            style: {"width":"30px"},
            class: "p-mx-2 p-button-warning",
            icon: "pi pi-history",
            onClick: _ctx.reset
          }, null, 8, ["disabled", "onClick"]),
          _createVNode(_component_Button, {
            disabled: !_ctx.isShowModal,
            style: {"width":"30px"},
            loading: _ctx.loading,
            class: "p-button-success",
            icon: "pi pi-check-circle",
            onClick: _ctx.getResult
          }, null, 8, ["disabled", "loading", "onClick"])
        ]),
        _: 1
      }, 8, ["blocked"])
    ]),
    _createVNode(_component_Divider, { class: "p-my-0" }),
    _createElementVNode("section", {
      style: _normalizeStyle(`height:${_ctx.height || 250}px`),
      class: "p-d-flex p-align-center p-justify-center p-p-2"
    }, [
      (_ctx.isShowModal)
        ? (_openBlock(), _createBlock(_component_VuePictureCropper, {
            key: 0,
            boxStyle: {
          width: '100%',
          height: '100%',
          backgroundColor: '#f8f8f8',
          margin: 'auto',
        },
            img: _ctx.pic,
            options: {
          viewMode: 1,
          dragMode: 'crop',
        }
          }, null, 8, ["img"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.resultUrl || _ctx.src)
              ? (_openBlock(), _createBlock(_component_Image, {
                  key: 0,
                  preview: "",
                  basic: "",
                  height: (_ctx.height || 250) - 20,
                  src: _ctx.resultUrl || _ctx.src,
                  class: "p-mx-2"
                }, null, 8, ["height", "src"]))
              : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("previewImage")), 1))
          ], 64)),
      (_ctx.$slots.default)
        ? _renderSlot(_ctx.$slots, "default", { key: 2 }, undefined, true)
        : _createCommentVNode("", true)
    ], 4),
    _createVNode(_component_Divider, { class: "p-my-0" }),
    _createVNode(_component_BlockUI, {
      blocked: _ctx.loading || !_ctx.isShowModal,
      class: "p-d-flex p-p-2"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-text p-button-secondary p-mr-3",
          style: {"width":"30px"},
          icon: "pi pi-arrows-alt",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.secondaryAction('move')))
        }),
        _createVNode(_component_Button, {
          class: "p-button-text p-button-secondary",
          style: {"width":"30px"},
          icon: "pi pi-search-plus",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.secondaryAction('zoom-in')))
        }),
        _createVNode(_component_Button, {
          class: "p-mx-3 p-button-text p-button-secondary",
          style: {"width":"30px"},
          icon: "pi pi-search-minus",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.secondaryAction('zoom-out')))
        }),
        _createVNode(_component_Button, {
          class: "p-button-text p-button-secondary",
          style: {"width":"30px"},
          icon: "pi pi-replay",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.secondaryAction('rotate-left')))
        }),
        _createVNode(_component_Button, {
          class: "p-mx-3 p-button-text p-button-secondary",
          style: {"width":"30px"},
          icon: "pi pi-refresh",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.secondaryAction('rotate-right')))
        }),
        _createVNode(_component_Button, {
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.secondaryAction('flip-horizontal'))),
          class: "p-button-text p-button-secondary",
          style: {"width":"30px"},
          icon: "pi pi-arrows-h"
        }),
        _createVNode(_component_Button, {
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.secondaryAction('flip-vertical'))),
          icon: "pi pi-arrows-v",
          style: {"width":"30px"},
          class: "p-mx-3 p-button-text p-button-secondary"
        })
      ]),
      _: 1
    }, 8, ["blocked"]),
    _withDirectives(_createElementVNode("input", {
      ref: "uploadInput",
      type: "file",
      accept: "image/*",
      onChange: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.selectFile && _ctx.selectFile(...args)))
    }, null, 544), [
      [_vShow, false]
    ])
  ]))
}